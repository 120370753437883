@font-face {
  font-family: Cinzel;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("cinzel-latin-ext-400-normal.ad0f2ff8.woff2") format("woff2"), url("cinzel-all-400-normal.85d90f7a.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Cinzel;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("cinzel-latin-400-normal.586f64b1.woff2") format("woff2"), url("cinzel-all-400-normal.85d90f7a.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*# sourceMappingURL=en.4dcba68c.css.map */
